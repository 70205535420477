<template>
  <div class="loginD" v-loading="loading">
    <!-- 公告弹框 -->
    <el-dialog
      v-if="isFirstShow"
      class="dialog"
      title="系统公告"
      :visible.sync="isFirstShow"
      :before-close="handleClose"
      width="55%"
    >
      <!-- 公告内容列表 -->
      <ReportList :pageData="pageData" :detailContentSent="detailContentSent" @pageChange="pageChange" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="isFirstShow = false">取 消</el-button>
        <el-button type="primary" @click="isFirstShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <div ref="header"><Header ref="log" @isShowLogin="isShowLogin" @isShowJBrLogin="isShowJBrLogin" /></div>
    <div
      class="banner flex-center-align flex-column"
      :style="{
        minHeight: minHeight + 'px',
        backgroundSize: backgroundSize,
      }"
    >
      <!--<img class="imgLogo" src="../../assets/images/dashboard/logo2.png" height="81" />-->
      <!-- <img class="imgLogo" :src="logo" height="81" /> -->
      <div class="icon">
        <el-row>
          <div v-for="(item, i) in routerIcon" :key="i" :span="2" class="box">
            <el-col class="padding" @click.native="next(item.path, item.title)">
              <img :src="item.img" />
              <div class="mt-base">{{ item.title }}</div>
            </el-col>
          </div>
        </el-row>
      </div>
    </div>
    <fromZJ />
    <!-- <fromYGDJ /> -->
    <div ref="footer"><Footer @getLxwm="getLxwm" /></div>
    <el-dialog title="提示" :visible.sync="dialogVisibleLxwm" width="45%">
      <div class="tsCont">
        <p>1、账号注册登录的问题请联系新疆维吾尔自治区人民政府网新疆政务服务网，联系电话：0991-7531791。</p>
        <p>2、具体业务咨询请致电12345转各地不动产登记中心。</p>
        <p>
          3、系统故障问题请联系新疆维吾尔自治区不动产登记中心，可以致电12345转自治区不动产登记中心或加qq群：205706559。
        </p>
      </div>

      <p style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="dialogVisibleLxwm = false">确 定</el-button>
      </p>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="isShow" width="360px">
      <LoginDialog />
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="isShowJBr" width="360px">
      <loginCaDialog />
    </el-dialog>
  </div>
</template>

<script>
import Header from './header/index.vue';
import fromZJ from './component/fromZJ.vue';
import Footer from '../../layout/Footer.vue';
import loginCaDialog from './loginCaDialog';
import { localDataUser } from '@/pages/iebdc/utils/local-data.js';
import LoginDialog from './LoginDialog';
import { mapGetters } from 'vuex';
import bsdtDialog from '@iebdc/mixin/bsdtDialog';
import { mapMutations } from 'vuex';
import zwwLogin from '@iebdc/utils/zwwLogin.js';
// import fromYGDJ from './component/fromYGDJ.vue';
import {
  checkTokenByAppKey,
  userInfo,
  // getCode
} from '../../api/login';
import { getNodesByParam } from '@iebdc/utils/common';
import { localDataArea } from '@iebdc/utils/local-data.js';
import { getCookie, addCookie, removeCookie } from '@iebdc/utils/cookies';
import { initData } from './common';
import { getAgentUserList } from 'iebdc/api/my-operator';
import { set } from 'date-fns';
import { getPolicyData, getPolicyDetail } from '@iebdc/api/policy-service';
import ReportList from '@iebdc/views/new-dashboard/component/report-list.vue';

const userTypes = ['gr', 'jg'];
export default {
  name: 'index',
  components: {
    Header,
    fromZJ,
    // fromYGDJ,
    Footer,
    LoginDialog,
    loginCaDialog,
    ReportList,
  },
  mixins: [bsdtDialog],
  inject: ['customImagePath'],
  data() {
    return {
      pageData: {}, //公告列表数据
      detailData: {},
      detailContentSent: [], //
      title: '',
      artitle: '',
      isFirstShow: true,
      dialogVisibleLxwm: false,
      //
      logo: '',
      bg: '',
      minHeight: '',
      userType: '',
      backgroundSize: '100% 100%',
      ready: false,
      isShow: false,
      updateIcon: '1',
      userId: '',
      isShowJBr: false,
      userName: '',
      userData: localDataUser.get(),
      routerIcons: [
        {
          img: require('../../assets/images/dashboard/wybl.png'),
          title: '我要办理',
          path: '/home/check-in',
          permission: 'IEBDC:WYSQ',
        },
        {
          img: require('../../assets/images/dashboard/wybl.png'),
          title: '我要办理',
          path: '/home/check-in1',
          permission: 'IEBDC:KSWYSQ',
        },
        {
          img: require('../../assets/images/dashboard/jdcx.png'),
          title: '进度查询',
          path: '/home/info-search/progress-query',
          permission: 'IEBDC:JDCX',
        },
        {
          img: require('../../assets/images/dashboard/dzzzcx.png'),
          title: ' 电子证照查询',
          path: '/home/info-search/electronic-license',
          permission: 'IEBDC:SY:DZZZ',
        },
        {
          img: require('../../assets/images/dashboard/flfg.png'),
          title: '不动产(房产)查询',
          path: '/home/info-search/newQuanSZM-query',
          permission: 'IEBDC:SY:BDCZMCX',
        },
        // {
        //   img: require('../../assets/images/dashboard/service-icon6.png'),
        //   title: '我要查询',
        //   path: '/home/info-search',
        //   permission: 'IEBDC:CX',
        // },
        {
          img: require('../../assets/images/dashboard/bszl.png'),
          title: '办事指南',
          path: '/home/xxfw/guidance',
          permission: 'IEBDC:BSZN',
        },
        {
          img: require('../../assets/images/dashboard/flfg1.png'),
          title: '政策法规',
          path: '/home/xxfw/policy-law',
          permission: 'IEBDC:ZCFG',
        },
        {
          img: require('../../assets/images/dashboard/gsgg1.png'),
          title: '公示公告',
          path: '/home/xxfw/public-announcement',
          permission: 'IEBDC:GGGS',
        },
        {
          img: require('../../assets/images/dashboard/service-icon6.png'),
          title: '资料下载',
          path: '/home/xxfw/file-download',
          permission: 'IEBDC:ZLXZ',
        },
        // {
        //   img: require('../../assets/images/dashboard/bszl.png'),
        //   title: '批量办理',
        //   path: '/home/batchProcessing',
        //   permission: 'IEBDC:BSZN',
        // },
      ],
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['selectedBsdtTitle']),
    permission() {
      return this.$store.state.permission.permission;
    },
    isLogined() {
      return !this.userData;
    },
    routerIcon() {
      return this.routerIcons.filter((user) => {
        return this.$hasPermission(user.permission); //返回isShow=true的项，添加到activeUsers数组
      });
    },
  },
  watch: {
    permission(newVal, oldVal) {
      this.$forceUpdate();
    },
    $route(to, from) {
      console.log('===', to.path);
      this.init();
    },
  },
  created() {
    // 公告列表
    this.getPolicyData(0);
    this.initSystemInfo();
    zwwLogin.analysisUrl(
      window.location.href,
      () => {
        this.loading = true;
        this.getUrlData();
      },
      () => {
        this.loading = false;
        if (localStorage.getItem('IEBDC_ZWW_SX')) {
          setTimeout(() => {
            window.history.replaceState(
              '',
              '',
              process.env.NODE_ENV === 'production' ? 'iebdc.html#/home/check-in1' : 'iebdc#/home/check-in1'
            );
            this.$router.push({ path: '/home/check-in1' });
          }, 200);

          // location.reload();
        } else {
          window.history.replaceState(
            '',
            '',
            process.env.NODE_ENV === 'production' ? 'iebdc.html#/home-sign-in' : 'iebdc#/home-sign-in'
          );
          location.reload();
        }
      },
      () => {
        window.history.replaceState(
          '',
          '',
          process.env.NODE_ENV === 'production' ? 'iebdc.html#/home-login-out' : 'iebdc#/home-login-out'
        );
        this.$forceUpdate();
      }
    );
  },

  mounted() {
    this.contentBoxH();
    window.onresize = () => {
      this.contentBoxH();
    };
    if (this.userData) {
      //切换成企业用户后获取之前保存的经办人
      if (
        this.userData.hasOwnProperty('jbrListData') &&
        !this.$store.getters['jbrlist-data/getJbrListData'].hasOwnProperty('institutionaluser')
      ) {
        this.$store.commit('jbrlist-data/setJbrListData', this.userData.jbrListData);
      }
      //个人用户登录获取经办人
      console.log(this.userData.userType == 'gr' && !window.jbrname && !this.userData.hasOwnProperty('jbrListData'));
      if (this.userData.userType == 'gr' && !window.jbrname && !this.userData.hasOwnProperty('jbrListData')) {
        window.jbrname = 'jbr';
        getAgentUserList({
          name: this.userData.name,
          sfzh: this.userData.zjh,
        }).then((res) => {
          if (res.success && res.data) {
            //经办人无公司信息返回时,没有页面弹出
            if (res.data.hasOwnProperty('institutionaluser') && res.data.institutionaluser.length > 0) {
              //将经办人信息放入vuex
              this.$store.commit('jbrlist-data/setJbrListData', res.data);
              this.$nextTick(() => {
                this.$jbr();
              });
            }
          } else {
            // this.$message.error(res.message)
          }
        });
      }
      //是否活体实名
      // 判断是否第一次进入页面
      if (window.name === '') {
        window.name = 'isRefresh';
      } else {
        this.isFirstShow = false;
      }
    }
  },
  methods: {
    initSystemInfo() {
      const { APP_INFO } = window.CONFIG;
      this.bg = this.customImagePath + APP_INFO.iebdc.bg;
      this.logo = this.customImagePath + APP_INFO.iebdc.dashboard;
    },
    getUrlData() {
      const url = window.location.href; //获取当前url
      let cs = {};
      const query = url.split('?')[0]; //获取?之后的参数字符串0
      const queryCade = query.split('#')[0];
      cs = this.$route.query;
      if (cs.wwsq && cs.lcdm) {
        const query = url.split('?')[1]; //获取?之后的参数字符串
        if (window.CONFIG.APP_INFO.areaZw.hasOwnProperty(cs.commonareacode)) {
          cs.commonareacode = window.CONFIG.APP_INFO.areaZw[cs.commonareacode];
        }
        localStorage.setItem('IEBDC_ZWW_SX', JSON.stringify(cs));
        initData(() => {
          this.getCode();
        });
      }
    },
    isShowJBrLogin() {
      this.isShowJBr = true;
    },
    getLxwm() {
      console.log('123');
      this.dialogVisibleLxwm = true;
    },
    getCode() {
      //
      let result = getNodesByParam(
        localDataArea.get(),
        'code',
        JSON.parse(localStorage.getItem('IEBDC_ZWW_SX')).commonareacode,
        'childrens'
      );

      const nodes = [];
      const titles = [];
      let officeCode = '';
      let officeName = '';
      let jgmc = '';
      let djjg = '';

      if (result.length > 0) {
        result = result[0];

        if (result.pids != null) {
          result.pids = result.pids.substring(0, result.pids.length - 1);
          const data = result.pids.split(',');
          //三级自动选择
          let node = '';
          let title = '';
          data.forEach((ele) => {
            node = getNodesByParam(JSON.parse(localStorage.getItem('finalAreaDataLocal')), 'id', ele, 'childrens')[0];
            title = getNodesByParam(JSON.parse(localStorage.getItem('finalAreaDataLocal')), 'id', ele, 'childrens')[0];
            nodes.push(node.code);
            titles.push(title.title);
          });
          if (data.length === 1) {
            nodes.push(result.childrens[0].code);
            titles.push(result.childrens[0].title);
            officeCode = result.childrens[0].bsdtData[0].officeCode;
            officeName = result.childrens[0].bsdtData[0].officeName;
            jgmc = result.childrens[0].bsdtData[0].jgmc;
            djjg = result.childrens[0].bsdtData[0].djjg;
          } else if (data.length === 2) {
            officeCode = result.bsdtData[0].officeCode;
            officeName = result.bsdtData[0].officeName;
            jgmc = result.bsdtData[0].jgmc;
            djjg = result.bsdtData[0].djjg;
          }
          nodes.push(result.code);
          titles.push(result.title);
        } else {
          nodes.push('650000', '650100', '650101');
          titles.push('新疆维吾尔自治区', '乌鲁木齐市', '市辖区');
          officeCode = '650101-1';
          officeName = '乌市不动产登记中心-房产大厦';
          jgmc = '650100';
          djjg = '650101';
        }
      }
      this.$store.commit('dashboard/setSelectedShengCode', nodes[0]);
      this.$store.commit('dashboard/setSelectedShengTitle', titles[0]);
      this.$store.commit('dashboard/setSelectedShiCode', nodes[1]);
      this.$store.commit('dashboard/setSelectedShiTitle', titles[1]);
      this.$store.commit('dashboard/setSelectedAreaCode', nodes[2]);
      this.$store.commit('dashboard/setSelectedAreaTitle', titles[2]);
      this.$store.commit('dashboard/setSelectedBsdtCode', officeCode);
      this.$store.commit('dashboard/setSelectedBsdtTitle', officeName);
      this.$store.commit('dashboard/setSelectedOrganizationsCode', djjg);
      this.$store.commit('dashboard/setSelectedOrganizationsTitle', jgmc);
      addCookie('selectedBsdtTitle', this.$store.state.dashboard.selectedBsdtTitle, 1);
      addCookie('selectedBsdtCode', this.$store.state.dashboard.selectedBsdtCode, 1);
      addCookie('selectedOrganizationsTitle', this.$store.state.dashboard.selectedOrganizationsTitle, 1);
      addCookie('selectedOrganizationsCode', this.$store.state.dashboard.selectedOrganizationsCode, 1);
      addCookie('selectedShengTitle', this.$store.state.dashboard.selectedShengTitle, 1);
      addCookie('selectedShengCode', this.$store.state.dashboard.selectedShengCode, 1);
      addCookie('selectedShiTitle', this.$store.state.dashboard.selectedShiTitle, 1);
      addCookie('selectedShiCode', this.$store.state.dashboard.selectedShiCode, 1);
      addCookie('selectedAreaTitle', this.$store.state.dashboard.selectedAreaTitle, 1);
      addCookie('selectedAreaCode', this.$store.state.dashboard.selectedAreaCode, 1);
      this.$bus.$emit('selectedOrganizationsCode', djjg);
    },
    contentBoxH() {
      const screeHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const headerHeight = this.$refs.header.offsetHeight;
      // let footerHeight = this.$refs.footer.offsetHeight;
      const footerHeight = 148;
      let height = screeHeight - headerHeight - footerHeight;
      if (height <= 360) {
        height = 360;
      }
      this.minHeight = height;
    },
    //图标的下一步
    next(path, title) {
      // 办事指南、政策法规、公示公告未登录也可查看。办事指南,所有配置流程全部显示，未登录不可办理，登陆后，后台设置为不展示的也不可办理
      if (title === '办事指南' || title === '公示公告' || title === '政策法规' || title === '我要查询') {
        this.$router.push(path);
        return;
      }
      //图标跳转
      console.log(this.isLogined, 'this.isLogined');

      if (this.isLogined === false) {
        if (!this.bsdtDialog()) {
          return;
        }
        this.$router.push(path);
        //this.loading = true;
        1;
      } else {
        // this.$confirm('', '提示', {
        //   confirmButtonText: '立马登录',
        //   cancelButtonText: '暂不登录',
        //   type: 'warning',
        //   center: true,
        // })
        //   .then(() => {
        //     this.$refs.log.goHnlogin();
        //   })
        //   .catch();
        console.log(111, !this.$hasPermission('IEBDC:DL:TYRZ'));

        if (this.$hasPermission('IEBDC:DL:TYRZ')) {
          this.$confirm('', '提示', {
            confirmButtonText: '立马登录',

            cancelButtonText: '暂不登录',

            type: 'warning',
            center: true,
          })
            .then(() => {
              this.$refs.log.goHnlogin();
            })
            .catch();
        } else {
          this.isShow = true;
        }
      }
    },
    isShowLogin() {
      this.isShow = true;
    },
    // 获取公告
    getPolicyData(current) {
      this.pageData = {};
      getPolicyData({
        nCurrent: current,
        articleTypeEnum: 'GGGS',
        title: this.title,
        category: 'XTGG',
        visible: true,
        valid: true,
      }).then((res) => {
        const data = res.data;
        if (data.recordCount == 0) {
          this.isFirstShow = false;
        }
        this.pageData = data;
        // (data.page || []).forEach((item) => {
        //   this.pageData = data;
        // let num = { id: item.id };
        // getPolicyDetail(num).then((res) => {
        //   if (res && res.data) {
        //     const detailData = res.data.contents;
        //     this.detailContentSent.push({ id: num, detail: detailData });
        //   }
        // });
        // });
      });
    },
    // 查看文章详细内容
    checkDetail(id) {
      getPolicyDetail({ id }).then((res) => {
        if (res && res.data) {
          this.detailData = res.data;
          this.detailContentSent.push(this.detailData.contents);
        }
      });
    },
    // 分页查询
    pageChange(currentPage) {
      this.getPolicyData(currentPage - 1);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.isFirstShow = false;
          done();
        })
        .catch((_) => {});
    },
    ...mapMutations('user-type', ['setUserType']),
  },
};
</script>

<style scoped lang="scss">
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
.tsCont {
  padding: 10px;
  p {
    line-height: 30px;
  }
}
.banner {
  width: 100%;
  //background: url(../../assets/images/dashboard/bg.png) no-repeat center center;
  //background-size: 100% 100%;
  // padding: 50px 0 0 0;
  position: relative;
  .mb-42 {
    margin-bottom: 42px;
  }
  img.imgLogo {
    height: 80px;
    width: auto;
  }
  .btn {
    /deep/ .el-button {
      color: #ffffff;
      border-radius: 4px;
      box-shadow: 1px 1px 1px 0 rgba(0, 29, 50, 0.45);
      font-size: 20px;
      &:nth-child(1) {
        background-color: #2c8cff;
        border-color: #2c8cff;
      }
      &:nth-child(2) {
        background-color: #4a8cbc;
        border-color: #4a8cbc;
      }
    }
  }
  .icon {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    // padding: 50px 0;
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei, serif;
    opacity: 0.8;
    // margin: 51px 0 0 0;
    width: 100%;

    .el-col {
      -webkit-transition: -webkit-transform 0.2s;
      -moz-transition: -moz-transform 0.2s;
      transition: transform 0.2s;
      text-align: center;
      &:hover {
        cursor: pointer;
        transform: scale(1.1, 1.1);
      }
    }
    .el-row {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      // align-content: space-between;
      .box:nth-child(4) div {
        margin-right: 0;
      }
      .box:nth-child(8) div {
        margin-right: 0;
      }
    }
    .box {
      padding-top: 30px;
    }
  }
  .padding {
    padding: 0px 20px;
  }
  .mt-base {
    margin-top: $spacing-base;
    font-size: 24px;
    font-weight: 700;
    color: #3e454a;
    padding-top: 10px;
  }

  @media screen and (max-width: 1380px) {
    img.imgLogo {
      height: 60px;
      width: auto;
    }
    .icon {
      top: 60%;
    }
  }
}
.dialog /deep/ {
  overflow: auto;
}
/deep/ .el-dialog__body {
  // overflow: auto;
  img {
    width: 100%;
  }
}
/deep/ .login-dialog {
  /deep/ .el-dialog {
    width: 27%;
  }
}
.loginD /deep/ {
  background-color: #e5e6ec;
  .el-dialog__body {
    position: relative;
    //height: 440px;
    // padding: 20px 20px;
    border-top-width: 30px;
    padding-top: 0px;
    margin-top: 30px;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #282828;
    font-size: 20px;
  }
}
.padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 270px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgb(211, 211, 211);
  border-radius: 3px;
  margin: 0 30px 0 0;
}
.el-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
[v-cloak] {
  display: none !important;
}
</style>
